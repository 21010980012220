import type { OptionObjMustHaves } from '../interface-utils';

export const enurmYear = {
	2021: '12/01/2020',
	2022: '10/01/2021',
	2023: '08/01/2022'
};

export const pruebasDiagnosticasOptions: OptionObjMustHaves[] = [
	{ id: 'evaluacion-1', value: 'Prueba Diagnóstica 1' },
	{ id: 'evaluacion-2', value: 'Prueba Diagnóstica 2' }
];

export const testTypeOptions: OptionObjMustHaves[] = [
	{ id: 'pruebín', value: 'Pruebín' },
	{ id: 'simulación', value: 'Simulación' },
	{ id: 'otro', value: 'Otro' }
];

// TODO: Add CMF & dermato's test labels
// TODO: Need to separate these into 'default', 'admin', individual exams and 'evaluaciones diagnosticas' so Ican group the options in the select statement form
export const testLabelOptions = {
	enurmOnFreeTrial: [{ id: 'enurm-2020', value: 'ENURM 2020' }],
	enurmAdmin: [
		// TODO: Need to add these labels
	],
	enurm: [
		{ id: 'all', value: 'Todas las Preguntas' },
		{ id: 'prepmed', value: 'Propietarias de PrepMed' },
		// { id: 'evaluacion-1', value: 'Evaluación Diagnóstica 1' },
		// { id: 'evaluacion-2', value: 'Evaluación Diagnóstica 2' },
		// { id: 'nuevas', value: 'Preguntas no antes vistas' },
		// TODO: Should I move these to questionTypeOptions?
		{ id: 'correctas', value: 'Solo Correctas' },
		{ id: 'incorrectas', value: 'Solo Incorrectas' },
		{ id: 'enurm-2024', value: 'ENURM 2024' },
		{ id: 'enurm-2023-A', value: 'ENURM 2023' },
		{ id: 'enurm-2022-A', value: 'ENURM 2022 A' },
		{ id: 'enurm-2022-B', value: 'ENURM 2022 B' },
		{ id: 'enurm-2022-C', value: 'ENURM 2022 C' },
		{ id: 'enurm-2022-D', value: 'ENURM 2022 D' },
		{ id: 'enurm-2021-A', value: 'ENURM 2021 A' },
		{ id: 'enurm-2021-B', value: 'ENURM 2021 B' },
		{ id: 'enurm-2021-C', value: 'ENURM 2021 C' },
		{ id: 'enurm-2020', value: 'ENURM 2020' },
		{ id: 'enurm-2019', value: 'ENURM 2019' },
		{ id: 'enurm-2018', value: 'ENURM 2018' },
		{ id: 'enurm-2017', value: 'ENURM 2017' },
		{ id: 'enurm-2016', value: 'ENURM 2016' },
		{ id: 'enurm-2015', value: 'ENURM 2015' },
		{ id: 'enurm-2014', value: 'ENURM 2014' },
		{ id: 'enurm-2013', value: 'ENURM 2013' },
		{ id: 'enurm-2012', value: 'ENURM 2012' },
		{ id: 'enurm-2011', value: 'ENURM 2011' },
		{ id: 'enurm-2010', value: 'ENURM 2010' },
		{ id: 'enurm-2009', value: 'ENURM 2009' },
		{ id: 'enurm-2008', value: 'ENURM 2008' },
		{ id: 'enurm-2007', value: 'ENURM 2007' }
		// { id: 'enurm-2006', value: 'ENURM 2006' },
		// { id: 'enurm-2005', value: 'ENURM 2005' },
		// { id: 'enurm-2004', value: 'ENURM 2004' },
		// { id: 'enurm-2003', value: 'ENURM 2003' },
		// { id: 'enurm-2002', value: 'ENURM 2002' },
		// { id: 'enurm-2001', value: 'ENURM 2001' }
	],
	maxilofacial: [
		{ id: 'all', value: 'Todas las Preguntas' },
		{ id: 'prepmed', value: 'Propietarias de PrepMed' },
		{ id: 'correctas', value: 'Solo Correctas' },
		{ id: 'incorrectas', value: 'Solo Incorrectas' },
		// todo: Where are the rest?
		{ id: 'enurm-cmf-2021-A', value: 'ENURM CMF 2021 A' },
		{ id: 'enurm-cmf-2021-B', value: 'ENURM CMF 2021 B' },
		{ id: 'enurm-cmf-2021-C', value: 'ENURM CMF 2021 C' },
		{ id: 'enurm-cmf-2020', value: 'ENURM CMF 2020' },
		{ id: 'enurm-cmf-2019', value: 'ENURM CMF 2019' },
		{ id: 'enurm-cmf-2018', value: 'ENURM CMF 2018' },
		{ id: 'enurm-cmf-2017', value: 'ENURM CMF 2017' },
		{ id: 'enurm-cmf-2016', value: 'ENURM CMF 2016' },
		{ id: 'enurm-cmf-2012', value: 'ENURM CMF 2012' }
	],
	dermatología: [
		{ id: 'all', value: 'Todas las Preguntas' },
		{ id: 'prepderm', value: 'Propietarias de PrepMed' },
		{ id: 'bogaert', value: 'Bogaert' },
		{ id: 'correctas', value: 'Solo Correctas' },
		{ id: 'incorrectas', value: 'Solo Incorrectas' }
	],
	enarm: []
};
// TODO: Where should these be used?
export const questionTypeOptions: OptionObjMustHaves[] = [
	{ id: 'todas', value: 'Todas las preguntas' },
	{ id: 'nuevas', value: 'Sólo preguntas nuevas' },
	{ id: 'repetidas', value: 'Sólo preguntas repetidas' }
];

export const testModeOptions: OptionObjMustHaves[] = [
	{ id: 'aleatorio', value: 'Modo Aleatorio' },
	{ id: 'categorías', value: 'Categorías Específicas' }
];

export const timerOptions: OptionObjMustHaves[] = [
	{ id: '60', value: '1 Minutos' }, // ENABLE FOR TESTING
	{ id: '1800', value: '30 Minutos' },
	{ id: '3599.9', value: '1 Hora' },
	{ id: '5400', value: '1 Hora y Media' },
	{ id: '7200', value: '2 Horas' },
	{ id: '9000', value: '2 Horas y Media' },
	{ id: '10800', value: '3 Horas' }
];

// *This array can then be used to generate the options in your select element in your React component:
// <select>
// {options.map((option, index) => (
// <option key={index} value={option.value} disabled={option.disabled}>
// {option.label}
// </option>
// ))}
// </select>;
