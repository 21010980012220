import useUserContext from '../../../../api/UserContext';
import Alert from '../../../../components/ui/Alert';
import MainSection from '../../../../layout/app/dashboard/MainSection';
import routes from '../../../../config/routes';
import { Link } from 'react-router-dom';

import Description from './Description';
import SelectionForm from './SelectionForm';

const EvaluacionesDiagnosticas = () => {
	const { currentUser } = useUserContext();
	const isFreeTrialUser = currentUser?.freeTrial?.isActive ?? false;
	if (isFreeTrialUser) {
		return (
			<MainSection title='Evaluaciones Diagnósticas'>
				<Alert
					title='Tu cuenta está en período de prueba...'
					type='info'
					classes='px-8 mt-4'>
					<p>
						Actualmente no tienes acceso a esta sección. Para poder acceder a
						ella, adquiere una membresía{' '}
						<Link to={routes.CHECKOUT.CHECKOUT} className='btn bg-alt2-500'>
							Completar Pago
						</Link>
						.
					</p>
				</Alert>
			</MainSection>
		);
	}

	return (
		<MainSection title='Pruebas Diagnósticas'>
			<Alert
				title='¿Qué son las Pruebas Diagnósticas?'
				type='brand'
				classes='px-8 py-2 mt-4'>
				<p className='mb-0'>
					Es <strong>muy importante</strong> leer y entender bien la descripción
					debajo antes de realizar una de estas pruebas que hemos preparado para
					ti.
				</p>
			</Alert>
			<div className='flex flex-col lg:flex-row justify-between pt-8'>
				<Description />
				<SelectionForm />
			</div>
			<hr className='w-3/4 mx-auto my-8' />
			{/* // TODO: Add graphs here */}
		</MainSection>
	);
};

export default EvaluacionesDiagnosticas;
