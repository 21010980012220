import { Link, useLocation } from 'react-router-dom';
import MainSection from '../../../../layout/app/dashboard/MainSection';
import StickyScrollToTop from '../../../../components/utils/StickyScrollToTop';
import { useEffect, useState } from 'react';
import type {
	Question,
	Record,
	TestRecord
} from '../../../../data/interface-question';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import Section from '../../../../components/ui/Section';
import TestReviewQuestions from './TestReviewQuestions';
import TestReviewSummary from './TestReviewSummary';
import debugThis from '../../../../utils/helpers';
import routes from '../../../../config/routes';

const TestReview = () => {
	const location = useLocation();
	const { questions } = useQuestionBankContext();
	const [isFiltered, setIsFiltered] = useState(false);
	const [correctAnswers, setCorrectAnswers] = useState(0);
	const [testQuestionRecords, setTestQuestionRecords] = useState<Record[]>([]);
	const [filteredTQR, setFilteredTQR] = useState<Record[]>([]);
	const [questionList, setQuestionList] = useState<Question[]>([]);

	const { testRecord } = location.state;
	const record: TestRecord = testRecord;

	const filterRecordsBy = (condition: string) => {
		setIsFiltered(true);
		if (condition === 'all') {
			setIsFiltered(false);
			setFilteredTQR(testQuestionRecords);
		}

		// Correctas
		if (condition === 'correctas') {
			const correctQuestions = testQuestionRecords.filter((q) => {
				return q.selectedAnswer === q.answer;
			});
			setFilteredTQR(correctQuestions);
		}

		// Incorrectas
		if (condition === 'incorrectas') {
			const incorrectQuestions = testQuestionRecords.filter((q) => {
				return q.selectedAnswer !== q.answer;
			});
			setFilteredTQR(incorrectQuestions);
		}
	};

	useEffect(() => {
		if (questions !== undefined) {
			record.questions.forEach((r) => {
				const q = questions.find((question) => question.id === r.id);
				if (q !== undefined) {
					setQuestionList((prevValues) => [...prevValues, q]);
					const newRecord: Record = {
						...r,
						id: q.id,
						selectedAnswer: r.selectedAnswer,
						selectTS: r.selectTS,
						bank: q.bank,
						categoryID: q.categoryID,
						imageFileName: q.imageFileName,
						source: q.source,
						question: q.question,
						answer: q.answer,
						option1: q.option1,
						option2: q.option2,
						option3: q.option3,
						explanation: q.explanation,
						fuente: q.fuente,
						tags: q.tags,
						subCategoryList: q.subCategoryList,
						showImageInQuestion: q.showImageInQuestion ?? false,
						audioFileName: q.audioFileName,
						isSelectedAnswerCorrect: r.selectedAnswer === q.answer
					};
					if (newRecord.isSelectedAnswerCorrect) {
						setCorrectAnswers((prev) => prev + 1);
					}
					setTestQuestionRecords((prevRecords) => [...prevRecords, newRecord]);
					setFilteredTQR((prevRecords) => [...prevRecords, newRecord]);
				}
			});
		}
		return () => {
			setIsFiltered(false);
			setCorrectAnswers(0);
			setTestQuestionRecords([]);
			setQuestionList([]);
			setFilteredTQR([]);
		};
	}, [record, questions]);

	return (
		<MainSection title='' bgColor='bg-gray-100'>
			<StickyScrollToTop />
			{/* RESUMEN DEL EXAMEN */}
			<Section
				title='Resumen del Examen'
				subTitle='Explora un breve resumen de tu examen y revisa en detalle cada pregunta y la respuesta que elegiste.'>
				<TestReviewSummary correctAnswers={correctAnswers} record={record} />

				<hr className='w-3/4 mt-8 mb-4' />
				{/* BUTTONS */}
				<div className='flex flex-col md:flex-row items-end md:justify-end md:w-full md:mx-auto space-y-2 md:space-x-4 mb-4'>
					<Link
						to={routes.APP.INDEX}
						className='btn bg-brand-500 hover:bg-brand-600 text-gray-700 flex items-center'>
						<span className='material-icons mr-2'>dashboard</span>
						<span>Panel Principal</span>
					</Link>
					{/* // TODO - ENHANCEMENT: Convierte esto debajo a "Repetir Examen" y el componente de TestMode debe poder recibir un array de preguntas para repetir el examen. */}
					{/* <Link
						to={routes.APP.PATH_TO.QUESTION_SELECTIONS}
						className='btn bg-gray-300 hover:bg-gray-400 text-gray-700 flex items-center'>
						<span className='material-icons mr-2'>refresh</span>{' '}
						<span>Examínate Denuevo</span>
					</Link> */}
					<button
						className='bg-gray-300 hover:bg-green-400 active:bg-green-500 focus:bg-green-500 active:text-white focus:text-white text-gray-700 flex items-center'
						onClick={() => filterRecordsBy('correctas')}>
						<span className='material-icons mr-2'>filter_alt</span>
						Ver Solo Correctas
					</button>
					<button
						className='bg-gray-300 hover:bg-red-400 active:bg-red-500 focus:bg-red-500 active:text-white focus:text-white text-gray-700 flex items-center'
						onClick={() => filterRecordsBy('incorrectas')}>
						<span className='material-icons mr-2'>filter_alt</span>
						Ver Solo Incorrectas
					</button>
					<button
						className='bg-brand-500 hover:bg-brand-500 flex items-center'
						onClick={() => filterRecordsBy('all')}
						disabled={!isFiltered}>
						<span className='material-icons mr-2'>filter_alt_off</span>
						Ver Todas las Preguntas
					</button>
				</div>
				<TestReviewQuestions records={filteredTQR} />
			</Section>
		</MainSection>
	);
};
export default TestReview;
