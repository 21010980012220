const Description = () => {
	return (
		<div className='w-full lg:w-6/12 xl:w-7/12'>
			<p>
				Las pruebas diagnósticas de PrepMed son herramientas de evaluación
				diseñadas para medir el conocimiento médico y las habilidades clínicas
				de estudiantes y profesionales de la salud.{' '}
			</p>
			<p>
				Estas pruebas difieren del banco de preguntas de ENURM y PrepMed, en que
				son preguntas propietarias de nuestra plataforma y que medimos
				directamente contra el desempeño en el ENURM.
			</p>
			<p>
				Estas preguntas son el resultado de años de estudio del ENURM y fueron
				diseñadas evaluando los temas más recurrentes en el ENURM y utilizando
				técnicas de ponderación estadística para su desarrollo.
			</p>
			<p>
				Estas no son preguntas de estudio, estas preguntas son herramientas de
				medición.
			</p>
			<details className='mb-4'>
				<summary className='cursor-pointer hover:bg-brand-25'>
					<strong>¿Cómo Usar las Pruebas diagnósticas para prepararte?</strong>
				</summary>
				<ol className='normal-bullets m-4'>
					<li>Evalúa tu nivel inicial:</li>
					<ul className='normal-bullets ml-4'>
						<li>
							Realiza una Prueba diagnóstica al inicio de tu preparación para
							identificar áreas débiles.
						</li>
					</ul>
					<li>Haz revisiones dirigidas:</li>
					<ul className='normal-bullets ml-4'>
						<li>
							Usa los resultados para enfocar tu estudio en áreas específicas.
						</li>
						<li>
							Por ejemplo, si fallaste en farmacología, prioriza esa sección en
							tu revisión.
						</li>
					</ul>
					<li>Simula exámenes reales:</li>
					<ul className='normal-bullets ml-4'>
						<li>
							Realiza las Pruebas diagnósticas como si estuvieras en el día del
							examen: tiempo limitado y sin interrupciones.
						</li>
					</ul>
					<li>Monitorea tu progreso:</li>
					<ul className='normal-bullets ml-4'>
						<li>
							Realiza pruebas Pruebas diagnósticas regularmente para ver cómo
							evolucionan tus puntajes.
						</li>
						<li>
							Nuestra recomendación es hacer una prueba al inicio de tu estudio
							(primer 25% de nuestro banco) y otra al final de tu estudio
							(último 75% de nuestro banco)
						</li>
					</ul>
				</ol>
			</details>

			<details>
				<summary className='cursor-pointer hover:bg-brand-25'>
					<strong>¿Cómo funcionan las Pruebas diagnósticas?</strong>
				</summary>
				<div className='m-4'>
					<p>
						Estas pruebas buscan simular la experiencia ENURM lo más posible,
						por lo cual:
					</p>
					<ul className='normal-bullets'>
						<li>Cada una tiene 100 preguntas</li>
						<li>Cada una tiene un tiempo máximo de dos horas </li>
						<li>Las variables no pueden ser modificadas</li>
						<li>Las pruebas solo pueden ser tomadas UNA vez</li>
					</ul>
				</div>
			</details>
		</div>
	);
};
export default Description;
