import { useNavigate } from 'react-router-dom';
import routes from '../../../../config/routes';
import Headline from '../../../../components/ui/Headline';
import SelectElement from '../../../../components/forms/SelectElement';
import { pruebasDiagnosticasOptions } from '../../../../data/option-objects/examOptions';
import { useEffect, type SyntheticEvent, useState } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import Swal, { type SweetAlertResult } from 'sweetalert2';
import { useLoadingContext } from '../../../../context/LoadingContext';
import type {
	IPruebasDiagnostica,
	IQuestionBankReducerState,
	Question
} from '../../../../data/interface-question';
import Button from '../../../../components/ui/Button';
import type { OptionObjMustHaves } from '../../../../data/interface-utils';
import { set } from 'lodash';

const SelectionForm = () => {
	const navigateTo = useNavigate();
	const { setLoadingMessage, setLoading } = useLoadingContext();
	const {
		getQuestionsForPruebaDiagnostica,
		testRecords,
		testSelections,
		setTestSelections
	} = useQuestionBankContext();
	const [prueba, setPrueba] = useState<IPruebasDiagnostica | undefined>();
	const [testOptions, setTestOptions] = useState<OptionObjMustHaves[]>([]);

	const handleTestChange = (value: string) => {
		setPrueba(value as IPruebasDiagnostica);
	};

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		Swal.fire({
			title: 'Confirma...',
			html: '<div class="text-left"><p>Antes de empezar, recuerda que:</p> <ul class="normal-bullets ml-4"> <li>Cada prueba tiene 100 preguntas</li><li>Solo puedes hacer la prueba <b><u>una vez</u></b></li><li>Debes tener al menos <b>2 horas</b> disponibles para terminarla</li></ul><p>¿Estás seguro de que quieres empezar una prueba diagnóstica en este momento? </p></div>',
			icon: 'warning',
			allowOutsideClick: true,
			allowEscapeKey: true,
			allowEnterKey: true,
			showCancelButton: true,
			confirmButtonText: 'Empezar',
			customClass: {
				confirmButton: 'bg-brand-500 hover:bg-brand-700',
				cancelButton: 'bg-red-500 hover:bg-red-600'
			}
		})
			.then(async (result: SweetAlertResult) => {
				if (result.isConfirmed) {
					await handleStartTestConfirmation();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleStartTestConfirmation = async () => {
		setLoadingMessage('Cargando preguntas...');
		setLoading(true);

		if (prueba === undefined) {
			setLoading(false);
			await Swal.fire({
				title: 'Error',
				text: 'Debes seleccionar una prueba específica antes de continuar.',
				icon: 'error',
				customClass: {
					confirmButton: 'bg-brand-500 hover:bg-brand-700',
					cancelButton: 'bg-red-500 hover:bg-red-600'
				}
			});
		} else {
			const testQuestions: Question[] = await getQuestionsForPruebaDiagnostica(
				prueba
			);
			if (testQuestions.length === 0) {
				setLoading(false);
				await Swal.fire({
					title: 'Error',
					text: 'Hubo un problema cargando las preguntas. Por favor, intenta de nuevo.',
					icon: 'error',
					customClass: {
						confirmButton: 'bg-brand-500 hover:bg-brand-700',
						cancelButton: 'bg-red-500 hover:bg-red-600'
					}
				});
			} else {
				const selections: IQuestionBankReducerState = {
					allQuestions: [],
					categories: [],
					especialidades: [],
					label: 'none',
					questionType: 'todas',
					showCategorySection: false,
					showTopicSelectionBoxes: false,
					testDuration: '7200',
					testLabel: prueba,
					testLength: 100,
					testMode: 'aleatorio',
					testRecords: testRecords === null ? [] : [...testRecords],
					testType: 'simulación',
					timerStartTime: 0,
					topics: [],
					topicSubcategories: [],
					useTimer: true,
					useTutorMode: false,
					userCorrectQuestions: [],
					userIncorrectQuestions: [],
					questions: testQuestions
				};

				setTestSelections({ ...selections });
			}
		}
	};

	useEffect(() => {
		if (testSelections !== undefined) {
			// TEST IS READY TO START
			navigateTo(routes.APP.PATH_TO.TEST_MODE);
		}
	}, [testSelections]);

	useEffect(() => {
		if (testRecords !== null && testOptions.length === 0) {
			setLoadingMessage('Cargando pruebas...');
			setLoading(true);
			const options = [] as OptionObjMustHaves[];
			pruebasDiagnosticasOptions.forEach((option) => {
				const record = testRecords.find((r) => r.testLabel === option.id);

				if (record !== undefined && !option.value.includes('Completada')) {
					option.isDisabled = true;
					option.value = `${option.value} - Completada`;
				}
				options.push(option);
			});

			setTestOptions([...options] as OptionObjMustHaves[]);
			setLoading(false);
		}
	}, [testRecords]);

	return (
		<div className='w-full lg:w-6/12 xl:w-4/12'>
			<Headline
				title='Elige tu Prueba'
				type='h2'
				classes='text-base uppercase font-semibold mt-0 mb-4'
			/>
			<form onSubmit={handleSubmit}>
				<SelectElement
					defaultOption='- Elige una prueba -'
					id='test-type'
					icon='ballot'
					iconFam='material-icons'
					label='Elige un tipo de examen'
					options={testOptions}
					handleChange={(e) => {
						handleTestChange(e);
					}}
				/>
				<Button
					bgColor='bg-brand-500'
					hoverBgColor='hover:bg-brand-700'
					icon='send'
					iconFam='material-icons'
					iconPosition='right'
					classes='rounded px-4 py-2 transition duration-300 ease-in-out hover:bg-brand-700 mr-2'
					handleClick={handleSubmit}
					title='Empezar'
					isDisabled={prueba === undefined}
				/>
			</form>
		</div>
	);
};
export default SelectionForm;
